import React from 'react';

import Ad, { AdTypes } from '~/components/Ad/Ad';

import StyledComponent from '~/components/Styled/Styled';

import css from './MobileBannerAd.scss';

class MobileBannerAd extends React.Component<Props> {
  static displayName = 'MobileBannerAd';
  render() {
    return (
      <div className={css.mobileBannerAd}>
        <Ad adType={AdTypes.MOBILE_BANNER} />
      </div>
    );
  }
}

export default StyledComponent(MobileBannerAd, [css]);
